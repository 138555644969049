import React, {Component} from "react";

import SliderOfVideosByCategory from "./smart/SliderOfVideosByCategory";
import Featured from "../Featured";

class ListOfCategories extends Component {

    categories = [];

    constructor(props) {
        super(props);
        this.categories = props.categories;
    }

    render() {
        return (
            <div>
                {Object.keys(this.categories).map((item, i) => (
                    <div key={i}>
                        <Featured categoryId={this.categories[item].categoryId}/>
                        <SliderOfVideosByCategory
                            heading={this.categories[item].header}
                            apiCall={this.categories[item].apiCall}
                            categoryId={this.categories[item].categoryId}
                            icon={this.categories[item].icon}
                        />
                    </div>
                ))}
            </div>
        );
    };
}

export default ListOfCategories;