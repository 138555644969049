import React, {Component} from "react";
import {Link} from "react-router-dom";

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

class RowSliderNew extends Component {

    state = {
        isLoaded: false,
    };

    renderSlides() {
        return(this.props.slides);
    }
    componentDidUpdate(prevProps) {
        if(prevProps.slides !== this.props.slides) {
            this.setState({isLoaded:true});
        }
    }

    renderFakeSlides(){
        let fakeSlides = [];
        for (let i = 0; i< 15; i++) {
            fakeSlides.push(
                <div className={`tile ${this.state.isLoaded ? 'd-none':''}`} key={i}>
                    <div className="tile__media"/>
                    <div className="tile__details">
                        <div className="tile__title" />
                    </div>
                </div>
            );
        }

        return fakeSlides;
    }
    
    render() {
        return (
            <div className={`slider-container ${isMobileDevice()? 'mobile-list' : ''}`}>
                <Link to={this.props.link} style={{color:'#bc020f',textDecoration:'none'}}>
                    <h3 className="row_slide__title_link"><i className={this.props.icon}/> <span dangerouslySetInnerHTML={{__html: this.props.heading }}/> <small className="showOnLinkHover" style={{'fontSize':'small', textDecoration:'none', cursor: 'pointer'}}>Explore All</small></h3>
                </Link>
                <div className="row_slider">
                    <div className="row_slider__inner" id={`slider`+this.props.heading.replace( /\s/g, '')}>
                        {this.props.slides.length ? this.renderSlides() : this.renderFakeSlides()}
                    </div>
                </div>
            </div>
        );
    }
}

export default RowSliderNew;
