import React, { Component } from "react";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';

class SingleMovieNew extends Component {
  render() {
    const { movie,postNumber } = this.props;
    return (
      <Link to={"/video/" + movie.ID +"#"+postNumber}>
          <div className="tile__media">
              <LazyLoad height={200}>
              <img className="tile__img" src={movie.featured_image} alt=""  />
              </LazyLoad>
          </div>
          <div className="tile__details">
              <div className="tile__title" dangerouslySetInnerHTML={{__html: movie.post_title }}/>
          </div>
      </Link>
    );
  }
}

export default SingleMovieNew;