import React, {Component} from "react";
import Footer from "./includes/Footer";
import SingleMovieNoHover from "./includes/partial/SingleMovieNoHover.js";
import HeaderSlim from "./includes/HeaderSlim";
import {Link} from "react-router-dom";
import {gaTrackPageView} from './includes/partial/GoogleAnalytics'
import Navbar from "./includes/Navbar";

export default class TagPage extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
    }

    state = {
        categoryName: '',
        posts: {},
        subcategories: {},
    };

    fetchData = () => {
        const postUrl = fetch(
            `https://24hourhiphop.com/wp-json/myplugin/v1/category/${this.props.match.params.category_id}`
        );
        const subCategoryUrl = fetch(
            `https://24hourhiphop.com/wp-json/myplugin/v1/category/children/${this.props.match.params.category_id}`
        );

        const urls = [postUrl,subCategoryUrl];

        Promise.all(urls)
            .then(([r1,r3]) => Promise.all([r1.json(),r3.json()]))
            .then(([posts,subcategories]) => {
                if (this.mounted)
                    this.setState({
                        categoryName: posts.category.name,
                        posts: posts.posts,
                        subcategories: subcategories,
                    });
                console.log(this.state)

            })
            .catch(err => console.log(err));
    };


    componentDidMount() {
        console.log(this.props);
        this.mounted = true;
        this.fetchData();
        gaTrackPageView();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    renderCategories(){
        const {subcategories} = this.state;
        if(subcategories === null){
            return;
        }

        const categories = Object.keys(subcategories).length ? subcategories.map(subcategory => {
            return (
                <div className="menu-item" key={subcategory.term_id}>
                    <Link to={"/category/" + subcategory.term_id}>
                    {subcategory.name}
                    </Link>
                </div>
            );
        }) : '';

        return (
            <div>
                <div className='row'>
                    {categories}
                </div>
            </div>
        );
    }

    render() {
        const {posts} = this.state;

        const backgroundImg = {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7) , rgba(0, 0, 0, 0.7)), url("${posts.featured_image}")`
        };

        const postss = Object.keys(posts).length ? posts.map(movie => {
            return (
                <div className="menu-item" key={movie.id}>
                    <SingleMovieNoHover movie={movie}/>
                </div>
            );
        }) : '';


        const videos =
            Object.keys(posts).length ? (
                <div
                    style={backgroundImg}
                    className="back-height">
                    <div style={{textAlign:'center'}}>
                        <h3>Videos</h3>
                        <div style={{ clear: "both" }} />
                    </div>
                    <div className="content movie-card2_wrapper d-flex row">
                        {postss}
                    </div>
                </div>
            ) : (
                <p style={{textAlign: "center"}}>Loading...</p>
            );


        return (
            <div>
                <Navbar/>
                {/*<HeaderSlim categoryId={this.props.match.params.category_id}/>*/}
                {/*<List heading={categories[item].header} apiCall={categories[item].apiCall} categoryId={categories[item].categoryId} />*/}
                <div style={{textAlign:'center'}}>
                    <h1>{this.state.categoryName}</h1>
                    <div style={{ clear: "both" }} />
                </div>
                {this.renderCategories()}
                <div className="category-page">{videos}</div>
                <Footer/>
            </div>
        );
    }
}
