import React, {Component} from "react";
import Footer from "./includes/Footer";
import ListOfCategories from "./includes/partial/ListOfCategories";
import {gaTrackPageView} from './includes/partial/GoogleAnalytics'
import SliderOfShowsByCategory from "./includes/partial/smart/SliderOfShowsByCategory";
import Navbar from "./includes/Navbar";

export default class HomePage extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;

    };

    componentDidMount() {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
        this.mounted = true;

        gaTrackPageView();
    }

    state = {
        shows: []
    };

    shows = {
        allShows: {
            categoryId: 15569,
            apiCall: `https://24hourhiphop.com/wp-json/myplugin/v1/category/children/15569`,
            header: "Feature Shows"
        },
    };

    categories = {
        // movies: {
        //     categoryId: 15573,
        //     apiCall: "https://24hourhiphop.com/wp-json/myplugin/v1/category/15573",
        //     header: "Movies",
        // },
        music: {
            categoryId: 14742,
            apiCall: "https://24hourhiphop.com/wp-json/myplugin/v1/category/14742",
            header: "Music",
        },
        shows: {
            categoryId: 15569,
            apiCall: "https://24hourhiphop.com/wp-json/myplugin/v1/category/15569",
            header: "Shows",
        },
    };

    render() {
        document.title = "RapFlix: Free Streaming Hip Hop On Demand";
        document.getElementsByTagName("META")[2].content = "rapflix, 24hourhiphop, on demand, streaming, hip hop, urban, music videos, news";
        document.getElementsByTagName("META")[3].content = "Hip Hop Culture Streaming On Demand News, Music & Videos Powered By 24hourhiphop.com";

        return (
            <React.Fragment>
                <Navbar/>
                <ListOfCategories categories={this.categories}/>
                <SliderOfShowsByCategory
                    heading={`Shows`}
                    apiCall={this.shows['allShows'].apiCall}
                    categoryId={this.shows['allShows'].categoryId}
                    icon={`fas fa-tv`}
                />
                <Footer/>
            </React.Fragment>
        );
    }

};


