import ReactGA from 'react-ga';


export function gaTrackPageView() {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export function gaInitialize() {
    ReactGA.initialize('UA-2161503-7');
}

