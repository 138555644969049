import React, {Component} from "react";
import {Link} from "react-router-dom";

class Featured extends Component {
    constructor(props) {
        super(props);
        this.showcaseMovies = 0;
        this.mounted = false;
    }

    state = {
        posts: [],
    };

    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.categoryId !== this.props.categoryId) {
            this.fetchData();
        }
    }

    fetchData = () => {
        clearTimeout(this.timeout);
        fetch("https://24hourhiphop.com/wp-json/myplugin/v1/category/"+this.props.categoryId)
            .then(res => res.json())
            .then(data => {
                if (this.mounted) this.setState({posts: data.posts});
            })
            .catch(err => console.log(err));
    };


    componentWillUnmount() {
        this.mounted = false;
        clearTimeout(this.timeout);
    }

    renderFakeSlides(){
        return this.state.posts.map((post, index) => {
            if (index <= this.showcaseMovies) {
                return (
                    <div className="popular-background ratio-21-9" key={post.ID}>
                        {/*<div className="top_gradient"/>*/}
                        <div className="popular">
                    <div className='h-100'>
                        <Link to={"/video/" + post.ID}>
                            <div style={{
                                backgroundImage: `linear-gradient(0deg, rgba(24,24, 24,1) 0%, rgba(0, 0, 0, 0) 90%), url("${post.featured_image}")`,
                            }}
                                 className="bgImage"
                            >
                                <div className="popularInfo">
                                    <button style={{float:`right`}}>Watch <span className={`fa fa-xs fa-play`}/></button>
                                    <h1 dangerouslySetInnerHTML={{__html: post.post_title}} />
                                </div>
                            </div>
                        </Link>
                    </div>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        });
    }


    render() {

        return (
            <header>
                {this.renderFakeSlides()}
            </header>
        );
    }
}

export default Featured;
