import React, { Component } from "react";
import HomePage from "./pages/HomePage";
import CategoryPage from "./pages/CategoryPage";
import TagPage from "./pages/TagPage";
import PostPage from "./pages/PostPage";
// import Loading from "./pages/includes/Loading";
import {gaInitialize} from './pages/includes/partial/GoogleAnalytics'
import "./style.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';
import LoginModal from "./pages/includes/LoginModal";

// FIREBASE AUTHENTICATION CONFIGURATION
export const firebaseApp = firebase.initializeApp(firebaseConfig);

// let tag = document.createElement('script');
// tag.src = 'https://www.youtube.com/player_api';
// let firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


class App extends Component {
    render() {
    gaInitialize();

    const {
        user,
        signOut,
        signInWithFacebook,
        signInWithGoogle,
    } = this.props;

    window.user = user;
    window.signOut = signOut;
    window.signInWithFacebook = signInWithFacebook;
    window.signInWithGoogle = signInWithGoogle;


    return (
      <BrowserRouter>
        <div className="App">
          {/*<Loading/>*/}
          <LoginModal/>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/video/:post_id" component={PostPage} />
            <Route path="/category/:category_id" component={CategoryPage} />
            <Route path="/tag/:category_id" component={TagPage} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export const firebaseAppAuth = firebaseApp.auth();

export const providers = {
    emailProvider: new firebase.auth.EmailAuthProvider(),
    facebookProvider: new firebase.auth.FacebookAuthProvider(),
    googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
    providers,
    firebaseAppAuth,
})(App);