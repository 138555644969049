import React, {Component} from "react";
import Footer from "./includes/Footer";
import {Link} from "react-router-dom";
import {gaTrackPageView} from './includes/partial/GoogleAnalytics'
import SliderOfVideosByCategory from "./includes/partial/smart/SliderOfVideosByCategory";
import SliderOfShowsByCategory from "./includes/partial/smart/SliderOfShowsByCategory";
import Navbar from "./includes/Navbar";
import LazyLoad from 'react-lazyload';
import SingleMovieNew from "./includes/partial/SingleMovieNew";

export default class CategoryPage extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
    }

    shows = {
        allShows: {
            categoryId: 15569,
            apiCall: `https://24hourhiphop.com/wp-json/myplugin/v1/category/children/15569`,
            header: "Feature Shows"
        },
    };

    state = {
        category: {},
        categoryName: '',
        posts: {},
        subcategories: {},
    };

    fetchData = () => {
        gaTrackPageView();
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        window['renderFeaturedVideoRow'] = true;

        const postUrl = fetch(
            `https://24hourhiphop.com/wp-json/myplugin/v1/category/${this.props.match.params.category_id}`
        );
        const subCategoryUrl = fetch(
            `https://24hourhiphop.com/wp-json/myplugin/v1/category/children/${this.props.match.params.category_id}`
        );

        const urls = [postUrl, subCategoryUrl];

        Promise.all(urls)
            .then(([r1, r3]) => Promise.all([r1.json(), r3.json()]))
            .then(([posts, subcategories]) => {
                if (this.mounted)
                    this.setState({
                        category: posts.category,
                        categoryName: posts.category.name,
                        posts: posts.posts,
                        subcategories: subcategories,
                    });
                console.log(this.state)

            })
            .catch(err => console.log(err));
    };


    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }




    renderCategoryRow() {
        const {subcategories} = this.state;
        if (subcategories === null) {
            return;
        }

        return (
            <React.Fragment>
                {Object.keys(subcategories).length ? subcategories.filter(function (i) {
                    if (i.term_id !== 9243) {
                        return i;
                    }
                }).map(subcategory => {
                    return (
                        <div className="menu-item" key={subcategory.term_id}>
                            <LazyLoad height={635} offset={200}>
                                <SliderOfVideosByCategory
                                    heading={subcategory.name}
                                    apiCall={"https://24hourhiphop.com/wp-json/myplugin/v1/category/" + subcategory.term_id}
                                    categoryId={subcategory.term_id}
                                    minToDisplay={3}
                                />
                            </LazyLoad>
                        </div>
                    );
                }) : ''}
            </React.Fragment>
        );
    }
    renderParentCategoryLink() {
        const {category} = this.state;
        if (!Object.keys(category).length || !Object.keys(category.category_parent).length) {
            return;
        }

        return (
            <React.Fragment>
                <Link to={"/category/" + category.category_parent.term_id }>
                    {(Object.keys(category).length && Object.keys(category.category_parent).length) ? category.category_parent.cat_name : ''}
                </Link> /
            </React.Fragment>
        );
    }

    render() {
        const {posts} = this.state;

        return (
            <div>
                <Navbar/>
                {/*<HeaderLower categoryId={this.props.match.params.category_id}/>*/}
                <p>&nbsp;</p>
                <div className={`content`}>
                    {/*<small>{this.renderParentCategoryLink()}</small>*/}
                        <h1 dangerouslySetInnerHTML={{__html: this.state.categoryName }}/>
                    <div style={{clear: "both"}}/>
                    {this.state.category.description}
                </div>

                { this.props.match.params.category_id !== 15569 ? '' : <SliderOfShowsByCategory
                    heading={`Shows`}
                    apiCall={this.shows['allShows'].apiCall}
                    categoryId={this.shows['allShows'].categoryId}
                    icon={`fas fa-tv`}
                />
                }

                {this.renderCategoryRow()}

                <div className="category-page " style={this.state.style}>{Object.keys(posts).length ? (
                    <div
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7) , rgba(0, 0, 0, 0.7)), url("${posts.featured_image}")`
                        }}
                        className="back-height">
                        <div style={{clear: "both"}}/>
                        <div className={`content`}>
                            <div className={`mobile-list`}>
                                {Object.keys(posts).length ? posts.map(movie => {
                                    return (
                                        <div className="tile" key={movie.id}>
                                            <SingleMovieNew movie={movie}/>
                                        </div>
                                    );
                                }) : ''}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p style={{textAlign: "center"}}>Loading...</p>
                )}</div>
                <Footer/>
            </div>
        );
    }
}
