import React, {Component} from "react";
import Modal from 'react-modal';

class LoginModal extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;

        this.state = {
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openLoginModal = this.openLoginModal.bind(this);

        //setTimeout(this.openLoginModal,600000);
    };

    openLoginModal(){
        if(!window.user){
            this.openModal();
        }
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }
    render() {
        return (
            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                contentLabel="Example Modal"
            >
                <h1 className="modal__title" ref={subtitle => this.subtitle = subtitle}>{ window.user ?  `Welcome ${window.user.displayName}` : 'Keep Watching Free' }</h1>
                { window.user ?  <img src={window.user.photoURL}  style={{width:`100px`,borderRadius:`50%`}} onClick={window.signOut} alt={`Log out:  ${window.user.displayName}`}/> : <button onClick={window.signInWithGoogle} className={`signInBtn`}>Sign in with Google</button>}
                { window.user ?  <button className='modal__close-btn' onClick={this.closeModal}>close</button> : '' }
            </Modal>
        );
    }
}

export default LoginModal;