import React, {Component} from "react";
import {Link} from "react-router-dom";

class RowFeaturedVideo extends Component {

    constructor(props) {
        super(props);
        if (props.slide !== undefined) {
            this.slide = window.slide = props.slide;
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.slide !== prevProps.slide) {
            this.setState({
                slide: this.props.slide
            });
        }
    }

    componentDidMount() {
        if (this.slide) {
            let playerDefaults = {
                autoplay: false,
                autohide: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
                controls: 0,
                disablekb: 1,
                enablejsapi: 1,
                iv_load_policy: 3,
                playsinline: 1
            };

            let vid = [
                {
                    'videoId': window.slide.youtubeId,
                    'startSeconds': 10,
                    'endSeconds': 70,
                    'suggestedQuality': 'hd720'
                },
            ];

            let currVid = 0;
            let tv = new window['YT'].Player('tv' + window['slide'].ID, {
                width: '100%',
                height: '100%',
                events: {'onReady': onPlayerReady, 'onStateChange': onPlayerStateChange},
                playerVars: playerDefaults
            });


            function onPlayerReady() {
                tv.loadVideoById(vid[currVid]);
                //tv.pauseVideo();
                tv.mute();
            }

            let done = false;

            function onPlayerStateChange(event) {
                if (event.data === window['YT'].PlayerState.PLAYING && !done) {
                    setTimeout(function () {
                        currVid = (currVid === vid.length - 1) ? 0 : currVid + 1;
                        console.log("clip: " + currVid);
                        console.log(vid[currVid]);

                        tv.seekTo(vid[currVid].startSeconds);
                        done = false;
                    }, 30000);
                    done = true;
                }
            }
        }
    }


    render() {
        if (!this.slide) {
            return '';
        }

        window['renderFeaturedVideoRow'] = false;

        return (
            <React.Fragment>
                <div className="featured-video_wrapper">
                    <div style={{
                        backgroundImage: `linear-gradient(rgba(26, 26, 26, 0),rgba(26, 26, 26, 0),rgba(26, 26, 26, 0),rgba(26, 26, 26, .6),rgba(26, 26, 26, 1)),url("${this.slide.featured_image}")`,
                        height: `100%`,
                        backgroundSize: `cover`
                    }}>
                        <Link to={"/video/" + this.slide.ID}>
                            <div>
                                <div className="cover"/>
                                <div className="tv">
                                    {/*<div className="screen mute" id={`tv${this.slide.ID}`}/>*/}
                                    {/*<img width="560" height="315" src={this.slide.featured_image}/>*/}
                                    <iframe width="560" height="315" src={"https://www.youtube.com/embed/"+this.slide.youtubeId+"?&mute=1&autoplay=1"} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                                </div>
                            </div>
                            <div className="details">
                                <h1 dangerouslySetInnerHTML={{__html: this.slide.post_title}} style={{fontSize: `24px`, textShadow: `0px 0px 3px #000`}}/>
                                <div>
                                    <p>
                                        <button>Watch <span className={`fa fa-xs fa-play`}/></button>
                                    </p>
                                    {/*<p>*/}
                                        {/*<strong>{this.props.heading}</strong>*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default RowFeaturedVideo;
