import React, {Component} from "react";
import Navbar from "./includes/Navbar";
import Footer from "./includes/Footer";
import SliderOfVideosByCategory from "./includes/partial/smart/SliderOfVideosByCategory";
//import SliderOfVideosByTag from "./includes/partial/smart/SliderOfVideosByTag";
import {gaTrackPageView} from './includes/partial/GoogleAnalytics'
import SliderOfShowsByCategory from "./includes/partial/smart/SliderOfShowsByCategory";
import YouTubeVideo from "./includes/partial/YouTubeVideo";

export default class PostPage extends Component {

    shows = {
        allShows: {
            categoryId: 15569,
            apiCall: `https://24hourhiphop.com/wp-json/myplugin/v1/category/children/15569`,
            header: "Feature Shows"
        },
    };

    constructor(props) {
        super(props);

        this.mountStyle = this.mountStyle.bind(this);
        this.unMountStyle = this.unMountStyle.bind(this);

        this.mounted = false;
        this.state ={ // base css
            show: false,
            post: {},
            descriptionLink: `Show More`,
            styleDescription: {
                overflow: `hidden`,
                whiteSpace: `nowrap`,
                textOverflow: `ellipsis`,
            },
            style :{
                marginTop:`50px`,
                transformOrigin: `center center`
            }
        }
    }
    fetchPost = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        this.unMountStyle();

        let self = this;

        setTimeout(function(){
            const postUrl = fetch(
                `https://24hourhiphop.com/wp-json/myplugin/v1/post/${self.props.match.params.post_id}`
            );

            Promise.all([postUrl])
                .then(([r1]) => Promise.all([r1.json()]))
                .then(([post]) => {
                    if (self.mounted)
                        self.setState({
                            post: post,
                        });
                    self.mountStyle();
                })
                .catch(err => console.log(err));
        },500);
    };

    componentDidMount() {
        this.mounted = true;
        this.fetchPost();
        this.loadPage();
        gaTrackPageView();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.fetchPost();
        }
    }

    componentWillUnmount() {
        this.unMountStyle();
        this.mounted = false;
    }

    unMountStyle() { // css for unmount animation
        this.setState({
            show:false,
            style: {
                transform:`scale(.9)`,
                opacity: 0,
                transition: 'all .3s ease-out',
            }
        });
    }

    mountStyle() { // css for mount animation
        this.setState({
            show:true,
            style: {
                transform:`inherit`,
                opacity: 1,
                transition: 'all .1 ease-in',
            }
        })
    }

    loadPage(){
        this.unMountStyle();
        setTimeout(this.mountStyle, 500)
    }

    toggleDescription = () => {
        this.setState({
            styleDescription: {
                whiteSpace: (this.state.styleDescription.whiteSpace !== `nowrap`)?`nowrap`:`inherit`,
                overflow: `hidden`,
                textOverflow: `ellipsis`
            },
            descriptionLink: (this.state.styleDescription.whiteSpace !== `nowrap`)?`Show More`:`Show Less`
        });
    };

    renderMedia() {
        const {post} = this.state;
        let media = (Object.keys(post).length && post.youtubeId && this.state.show) ? (
            <div className={`media`}>
                <YouTubeVideo
                    id={post.youtubeId}
                    width={`100%`}
                    height={`100%`}
                    history={this.props.history}
                    showControls={1}
                />
            </div>
    ) : '';

        if (media === '') {
            media = (Object.keys(post).length) ? (
                <img src={`${post.featured_image}`}/>
            ) : '';
        }

        return (
            <React.Fragment>
                {media}
            </React.Fragment>
        )

    }

    render() {
        const {post} = this.state;
        if(!post){
            return ('');
        }

        const backgroundImg = {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7) , rgba(0, 0, 0, 0.7)), url("${post.featured_image}")`,
            filter: `blur(35px)`,
            width: `100%`,
            height: `100%`,
            position: `absolute`,
            zIndex: `-1`
        };

        if(Object.keys(post).length) {
            document.title = post.post_title + " | RapFlix";
            document.getElementsByTagName("META")[2].content = post.post_title.split(' ').join(',');
            document.getElementsByTagName("META")[3].content = "Description";
        }


        const content =
            Object.keys(post).length ? (
                <div>
                    <div

                        className="back-height">
                        <div style={backgroundImg} />
                        <div className="videoBackground">
                            <div className="video">
                                {this.renderMedia()}
                            </div>
                        </div>
                        <div className="content">
                            <h1 dangerouslySetInnerHTML={{__html: post.post_title }}/>

                            <p className="year-run-vote">
                              <span className="year">
                                {post.post_date}
                              </span>
                            </p>
                            <div className="overview-container">
                                <p className="overview"  style={this.state.styleDescription} dangerouslySetInnerHTML={{__html: post.post_content}}/>
                                <a href="javascript:void(0);" onClick={this.toggleDescription}>{this.state.descriptionLink}</a>
                            </div>
                        </div>
                    </div>
                    {/*{post.post_tag &&
                    post.post_tag.map((category) => {
                        return <span key={category.term_id}>
                                <SliderOfVideosByTag
                                    heading={category.name}
                                    apiCall={`https://24hourhiphop.com/wp-json/myplugin/v1/tag/${encodeURIComponent(category.name).replace(/%20/g, '-')}`}
                                    tagId={category.term_id}
                                    postId={post.ID}
                                    minToDisplay={2}
                                />
                            </span>;
                    })}*/}

                    {post.post_category &&
                    post.post_category.filter(function (i) {
                        if (
                            i.name !== 'Slideshow'
                            && i.name !== 'Hip Hop Videos'
                        ) {
                            return i;
                        }
                    }).map((category) => {
                        return  (
                            <span key={category.term_id}>
                                <SliderOfVideosByCategory
                                    heading={category.name}
                                    apiCall={"https://24hourhiphop.com/wp-json/myplugin/v1/category/" + category.term_id}
                                    categoryId={category.term_id}
                                    postId={post.ID}
                                />
                            </span>
                        );
                    })}

                    <SliderOfVideosByCategory
                        heading={`Just Added`}
                        apiCall={"https://24hourhiphop.com/wp-json/myplugin/v1/category/14740"}
                        categoryId={`14740`}
                        postId={post.ID}
                    />

                    <SliderOfShowsByCategory
                        heading={`Shows`}
                        apiCall={this.shows['allShows'].apiCall}
                        categoryId={this.shows['allShows'].categoryId}/>

                </div>
            ) : (
                <p style={{textAlign: "center"}}>Loading...</p>
            );

        return (
            <div>
                <Navbar/>
                <div style={this.state.style}>{content}</div>
                <Footer/>
            </div>
        );
    }
}
