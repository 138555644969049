import React, {Component} from "react";
import SingleMovieNew from "../SingleMovieNew.js";
import RowSliderNew from "../RowSliderNew";
import RowFeaturedVideo from "../RowFeaturedVideo";

class SliderOfVideosByCategory extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
    }

    state = {
        slides: [],
    };

    componentDidMount() {
        this.mounted = true;
        this.fetchData(this.props.apiCall);
    };

    componentWillReceiveProps(nextProps){
        if(nextProps.apiCall!==this.props.apiCall){
            this.fetchData(nextProps.apiCall);
        }
    }

    fetchData = (url) => {
        fetch(url)
            .then(r => r.json())
            .then(data => {
                if (this.mounted)
                    this.setState({slides: data.posts});
            })
            .catch(err => console.log(err));
    };

    componentWillUnmount() {
        this.mounted = false;
    };

    renderFeaturedVideoRow(){
        if(window['renderFeaturedVideoRow'] !== false) {
            return (this.state.slides &&
                <RowFeaturedVideo
                    heading={this.props.heading}
                    slide={this.state.slides[Math.floor(Math.random() * this.state.slides.length)]}
                />
            );
        }
    }

    render() {
        const {slides} = this.state;
        if (slides.length < this.props.minToDisplay) {
            return ('');
        }

        return (
            <React.Fragment>
                <RowFeaturedVideo
                    heading={this.props.heading}
                    slide={slides[0]}
                />

                <RowSliderNew
                    link={"/category/" + this.props.categoryId}
                    heading={this.props.heading}
                    slides={slides.map((slide, index) => {
                        return (
                            <div className="tile" key={index}>
                                <SingleMovieNew movie={slide} postNumber={index + ':'+ this.props.heading.replace( /\s/g, '')} />
                            </div>
                        );
                    })}
                    icon={this.props.icon}
                />

                {/*{this.renderFeaturedVideoRow()}*/}
            </React.Fragment>
        );
    }


}

export default SliderOfVideosByCategory;
