import React, {Component} from "react";
import RowSliderNew from "../RowSliderNew";
import SingleShowNew from "../SingleShowNew";

class SliderOfShowsByCategory extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
    }

    state ={
        slides:[]
    };

    componentDidMount() {
        this.mounted = true;

        fetch(this.props.apiCall)
            .then(r => r.json())
            .then(slides => {
                this.setState({slides:slides});
            })
            .catch(err => console.log(err));
    };

    componentWillUnmount() {
        this.mounted = false;
    };

    render() {
        const {slides} = this.state;
        const Slides = slides.sort(function() {
            return .5 - Math.random();
        }).map(slide => {
            return (
                <div className="tile tile-show" key={slide.term_id} style={{
                    backgroundImage: `url(${slide.image})`
                }}>
                    <SingleShowNew show={slide}/>
                </div>
            );
        });
        return (
            <React.Fragment>
                <RowSliderNew
                    link={"/category/" + this.props.categoryId}
                    heading={this.props.heading}
                    slides={Slides}
                    icon={this.props.icon}
                    />
            </React.Fragment>
        );
    }
}

export default SliderOfShowsByCategory;
