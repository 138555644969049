import React, { Component } from "react";
import { Link } from "react-router-dom";
import Search from "./Search";

export default class Navbar extends Component {

    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            scrolling: false,
            dropdownMenu: false,
            searchDropdown: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        if (window.scrollY <= 70) {
            this.setState({scrolling: false});
        }
        else if (window.scrollY > 70 ) {
            this.setState({scrolling: true});
        }
    }

    static closeMenuStates() {
        return {
            dropdownMenu: false,
            searchDropdown: false,
        };
    }

    handleDropdownClick = () => {
        const partialState = Navbar.closeMenuStates();
        this.setState({
            ...partialState,
            dropdownMenu: !this.state.dropdownMenu,
        });
    }


    handleSearchDropdownClick = () => {
        const partialState = Navbar.closeMenuStates();
        this.setState({
            ...partialState,
            searchDropdown: !this.state.searchDropdown,
        });
    }

    render(){
        return (
            <nav id="header-nav" className={`${this.state.scrolling?'bg-dark':''}`}>
                <div className="left-side d-flex align-items-center">
                    <div className="logo">
                        <Link to="/">
                            <div className="nav-logo-svg">
                                <img src={"/rapflix-logo.png"} alt=""/>
                            </div>
                        </Link>
                    </div>

                    <div className="nav-link_wrapper d-flex">
                        <Link className={`nav-link ${window.location.pathname === '/category/15573'? 'strong': ''}`} to="/category/15573">Movies</Link>
                        <Link className={`nav-link ${window.location.pathname === '/category/15569'? 'strong': ''}`} to="/category/15569">Shows</Link>
                        <Link className={`nav-link ${window.location.pathname === '/category/14742'? 'strong': ''}`} to="/category/14742">Music</Link>
                    </div>

                </div>

                <div className={`search-bar ${this.state.searchDropdown? 'show-dropdown': ''}`}>

                    <a href="javascript:void(0);" onClick={this.handleSearchDropdownClick} className="search-bar-dropdown-btn">
                        <i className="fas fa-search"/>
                    </a>
                    <Search />
                    { window.user ?  <img src={window.user.photoURL}  style={{width:`40px`,borderRadius:`50%`}} onClick={window.signOut} /> : <button onClick={window.signInWithGoogle}>Login</button>}
                    {/*{ window.user ?  '' : <button onClick={window.signInWithFacebook}>Sign in with Facebook</button>}*/}
                </div>
            </nav>
        );
    }
};