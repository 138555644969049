import React, { Component } from "react";
import { Link } from "react-router-dom";
// import LazyLoad from 'react-lazy-load';

class SingleShowNew extends Component {
  render() {
    const { show } = this.props;
    return (
      <Link to={"/category/" + show.term_id}>
          <div className="tile__media">
              <img className="tile__img" src={show.featured_image} alt=""  />
          </div>
          <div className="tile__details">
              <div className="tile__title" dangerouslySetInnerHTML={{__html: show.name }}/>
          </div>
      </Link>
    );
  }
}

export default SingleShowNew;