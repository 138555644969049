import React, {Component} from "react";
import Animate from "react-smooth";
import {Link} from "react-router-dom";

class HeaderSlim extends Component {
    constructor(props) {
        super(props);
        this.showcaseMovies = 4;
        this.timeoutTime = 5000;
        this.mounted = false;
    }

    state = {
        posts: [],
        i: 0
    };

    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.categoryId !== this.props.categoryId) {
            this.fetchData();
        }
    }

    fetchData = () => {
        clearTimeout(this.timeout);
        fetch("https://24hourhiphop.com/wp-json/myplugin/v1/category/"+this.props.categoryId)
            .then(res => res.json())
            .then(data => {
                if (this.mounted) this.setState({posts: data.posts});
            })
            .catch(err => console.log(err));
        this.startTimeout();
    };

    startTimeout = () => {
        this.timeout = setTimeout(() => {
            if (this.state.i < this.showcaseMovies)
                this.setState({i: this.state.i + 1});
            else this.setState({i: 0});
            this.startTimeout();
        }, this.timeoutTime);
    };

    componentWillUnmount() {
        this.mounted = false;
        clearTimeout(this.timeout);
    }

    render() {
        const {posts, i} = this.state;

        const divs = posts.length
            ? posts.map((movie, index) => {
                if (index <= this.showcaseMovies) {
                    return (
                        <div
                            key={index}
                            className={i === index ? "active" : null}
                            onClick={() =>
                                this.setState({i: index}, () => {
                                    clearTimeout(this.timeout);
                                    this.startTimeout();
                                })
                            }
                        />
                    );
                } else return null;
            })
            : null;

        const postsList = posts.length ? (
            <div className='h-100' key={posts[i].ID}>
                <Animate to="1" from="0" attributeName="opacity">
                    <Link to={"/video/" + posts[i].ID}><div
                        style={{
                            backgroundImage: `linear-gradient(0deg, rgba(24,24, 24,1) 0%, rgba(0, 0, 0, 0) 90%), url("${posts[i].featured_image}")`,
                        }}
                        className="bgImage"
                    >
                        <div className="popularInfo">
                            <button style={{float:`right`}}>Watch <span className={`fa fa-xs fa-play`}/></button>
                            <h1 dangerouslySetInnerHTML={{__html: posts[i].post_title}} />
                        </div>
                        <div className="switchImg">{divs}</div>
                    </div></Link>
                </Animate>
            </div>
        ) : ('');

        return (
            <header>
                <div className="popular-background ratio-21-9">
                    <div className="top_gradient"/>
                    <div className="popular">{postsList}</div>
                </div>
            </header>
        );
    }
}

export default HeaderSlim;
