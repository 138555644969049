import React, {Component} from "react";

class YouTubeVideo extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if(this.props.id !== prevProps.id) {
            this.setState({
                id: this.props.id
            });
        }
    }

    componentDidMount() {
        //const iv_load_policy_show_annotations = 1;
        const iv_load_policy_no_captions = 3;

        if (this.props.id) {
            let playerDefaults = {
                autoplay: this.props.autoPlay || 0,
                autohide: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
                controls: this.props.showControls || 0,
                disablekb: 1,
                enablejsapi: 0,
                iv_load_policy: iv_load_policy_no_captions,
                playsinline: this.props.playsInline || 1
            };

            let vid = [
                {
                    'videoId': this.props.id,
                    'suggestedQuality': 'hd720'
                },
            ];

            let onPlayerStateChange = (event) => {
                if ( event.data === 0 ) {
                    let urlHashArray = window.location.hash.split(':');
                    let selector = document.querySelectorAll('#slider'+urlHashArray[1]+' a');
                    if(selector.length){
                        let hash = urlHashArray[0].split('#')[1];
                        let url = selector[parseInt(hash)+1].href;
                        this.props.history.push(url.replace(window.location.origin,''));
                    }
                }
            };

            let tv = new window['YT'].Player('tv' + this.props.id, {
                width: this.props.width || 448,
                height: this.props.height ||252,
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                },
                playerVars: playerDefaults
            });

            function onPlayerReady() {
                tv.loadVideoById(vid[0]);
                //tv.mute();
            }
        }
    }


    render() {
        if (!this.props.id) {
            return '';
        }

        return (
            <React.Fragment>
                <div id={`tv${this.props.id}`} className={`media`}/>
            </React.Fragment>
        );
    }

}

export default YouTubeVideo;
