import React, {Component} from "react";
import {Link} from "react-router-dom";

class Search extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
    }

    state = {
        val: "",
        searchVal: [],
        showRes: false
    };

    componentDidMount() {
        this.mounted = true;
    }

    handleChange = e => {
        this.setState({val: e.target.value});
        if (e.target.value !== "")
            fetch(`https://24hourhiphop.com/wp-json/myplugin/v1/search/${encodeURIComponent(e.target.value).replace(/%20/g, '+')}`)
                .then(r => r.json())
                .then(data => {
                    if (this.mounted)
                        this.setState({searchVal: data, showRes: true});
                })
                .catch(err => console.log(err));
        else if (e.target.value === "") this.setState({showRes: false});
    };

    closeRes = () => {
        this.setState({showRes: false});
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const {val, searchVal, showRes} = this.state;

        const postss = searchVal.length
            ? searchVal.map(post => {
                return (
                    <li key={post.ID}>
                        <Link to={"/video/" + post.ID} onClick={this.closeRes}>
                            {post.post_title}
                        </Link>
                    </li>
                );
            })
            : null;

        return (
            <React.Fragment>
                <input
                    type="text"
                    name="searchVal"
                    onChange={this.handleChange}
                    className="search-input"
                    placeholder="Search"
                    value={val}
                />
                {showRes && (
                    <div className="search-values">
                        <ul>{postss}</ul>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default Search;
