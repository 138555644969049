import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class SingleMovieNoHover extends Component {
  state = {
    isHovering: false
  };

  handleEnter = () => {
    this.setState({ isHovering: true });
  };

  handleLeave = () => {
    this.setState({ isHovering: false });
  };

  render() {
    const { movie } = this.props;
    return (
      <Link to={"/video/" + movie.ID}>
        <div className="movie-card2 ratio ratio-16-9" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>

          <div className="img-bg" style={{background: `url(${movie.featured_image})no-repeat center center`, backgroundSize: 'cover'}} />
            {/*<img src={`${movie.featured_image}`} alt={movie.post_title} />*/}
          <p className="movie-title">{movie.post_title}</p>
        </div>

      </Link>
    );
  }
}
